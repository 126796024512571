import $request from './request.js'
const $http = $request.http
import $hi from './hi.js'


// 定义请求路由 
const API = {}

/* 
 * 判断数据类型 
 */
API.toType = (data) => {
	return Object.prototype.toString.call(data)
}

/* 
 * 路由标识
 * 字符串：api 方法， 为  GET 请求  lunbo: 'Lunbo/show'
 * 数组形式：第一个参数是 api 方法，第二个参数 是请求类型，默认 GET    lunbo: ['Lunbo/show', 'GET']
 */
const urls = {
	lunbos: 'Lunbos',
	navs: 'Navs', // 导航
	navSons: 'Nav/optSons', // 二维分类

	configs: 'Config/index', // 网站设置
	configHome: 'Config/home', // 首页设置

	articles: 'Articles', // 文章列表
	articleShow: 'Article/show', // 文章详情

	SmsSendEdit: ['SmsSend/edit', 'POST'], // 短信记录详情
	SmsSendShow: 'SmsSend/show', // 短信记录详情
	SmsSendIndexShow: 'SmsSend/indexShow', // 短信记录详情
	SmsSendJishu: 'SmsSend/jishu', // 短信访问计数

	OrderAdd: ['Order/add', 'POST'], // 下单
	OrderShow: ['Order/show', 'POST'], // 订单信息
	// OrderJiancha: ['Order/jiancha', 'POST'], // 订单检查
	GoPay: ['Order/goPay', 'POST'], // 去支付
	payChaxun: ['Order/payChaxun', 'POST'], // 支付信息查询

	weixinLoginCode: 'weixinLoginCode', // 微信服务号 内置 登录授权
	weixinLoginToken: 'weixinLoginToken', // 微信服务号 登录

}

/* 公共 路由请求
 * 使用方式： this.$api.any()
 * 
 */
API.any = (route, params = {}) => {
	let value = urls[route]
	var url = '' // 地址
	var method = "GET" // 请求类型
	// 判断 值得格式
	var type = API.toType(value)
	switch (type) {
		case "[object Array]":
			url = value[0]
			method = value[1] || "GET"
			break;

		case "[object String]":
			url = value
			break;
	}

	return $http(url, params, method);
}

/* 
 * 单独路由设置
 */
API.getConfig = (params = {}) => {
	return $http('Shiyi/getConfig', params)
}

/* *
 * 短信访问计数统计
 * 
 * code：短信发送记录  36 进制 ID
 * f：计数字段，逗号分隔
 */
API.onJishu = (code, field) => {
	if (code == '' || field == '') return
	return $http('SmsSend/jishu', {
		code: code,
		f: field
	})
}

// 调起支付 s ===============================
/**
 * 支付调起合集
 * pay_method：支付类型；
 * e：支付参数；
 * url: 跳转页面
 */
API.onPays = (pay_method, e, url = '') => {

	if (pay_method == 1) {
		return API.onYuePay(e, url)
	} else if (pay_method == 11) {
		return API.onWxPay11(e, url)
	} else if (pay_method == 12) {
		return API.onWxPay12(e, url)
	} else if (pay_method == 21) {
		return API.onZfbPay(e, url)
	} else {
		return $hi.isErr('请选择正确的支付方式')
	}
}
// 余额支付 后处理
API.onYuePay = (e, url = '') => {
	// var that = this
	$hi.isSucc('支付成功！')
	// 跳转详情
	$hi.toReplace(url, 3000)
}

// 调起微信支付 - H5
API.onWxPay11 = (e) => {
	$hi.toHref(e.pay_url, 0)  // 跳转调起 微信H5 支付
}
// 调起微信支付 - 内置
API.onWxPay12 = (e, url = '') => {
	window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
			"appId": e.appId, //公众号ID，由商户传入    
			"timeStamp": e.timeStamp, //时间戳，自1970年以来的秒数    
			"nonceStr": e.nonceStr, //随机串    
			"package": e.package,
			"signType": e.signType, //微信签名方式：    
			"paySign": e.paySign //微信签名
		},
		function(res) {
			if (res.err_msg == "get_brand_wcpay_request:ok") {
				// 使用以上方式判断前端返回,微信团队郑重提示：
				//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
				$hi.isSucc("支付成功")
				$hi.toPage(url, 3000)
			}
		}
	);
}

// 调起支付宝支付
API.onZfbPay = (e) => {
	// console.log(e)
	$hi.toHref(e.pay_url, 0)  // 跳转调起支付宝
}

// 调起支付 e ===============================



// 暴露
export default API