<template>

	<!-- 大头 s -->
	<!-- <header class="app-header hi-section">
		<div class="hi-bg header-bg">
			<img :src="configs.web_top_banner" alt="">
		</div>
		<div class="hi-main">
			<AppHeader :configs="configs"></AppHeader>
		</div>
	</header> -->
	<!-- 大头 e -->

	<div v-if="!heimingdna_ip">
		<!-- 主体 s -->
		<main id="app-main">
			<div class="app-main">
				<!-- 导航展示内容 -->
				<router-view />
			</div>
		</main>
		<!-- 主体 e -->
		
		<!-- 尾巴 s -->
		<footer class="app-footer">
			<AppFooter :item="configs"></AppFooter>
		</footer>
		<!-- 尾巴 e -->
		
		<!-- 客服浮框 s -->
		<HiKefu :phone="configs.web_phone"></HiKefu>
		<!-- 客服浮框 e -->
	</div>

</template>

<script>
	// import AppHeader from '@/components/AppHeader.vue'
	import AppFooter from '@/components/AppFooter.vue'
	import HiKefu from '@/components/HiKefu.vue'

	export default {
		name: 'App',
		components: {
			// AppHeader,
			AppFooter,
			HiKefu
		},
		data() {
			return {
				configs: [],
				heimingdna_ip: false
			}
		},
		created() {
			// 黑名单
			this.heimingdna_ip = sessionStorage.getItem('hi_heimingdan_ip')
			// console.log(this.heimingdna_ip)
			if (!this.heimingdna_ip) {
				this.getConfig(2)
			}
		},
		methods: {
			// 配置信息
			getConfig(navId) {
				var that = this
				that.$api.any('configs', {
					nav_id: navId,
				}).then(res => {
					that.configs = Object.assign(that.configs, res)
					// console.log(that.configs)
				})
			},
		}
	}
</script>