<template>
	<Transition name="down">
		<div class="my-meassage-box">
			<div class="my-message" :style="style[type]" v-show='isShow'>
				<!-- 上面绑定的是样式 -->
				<!-- 不同提示图标会变 -->
				<i class="iconfont" :class="[style[type].icon]"></i>
				<span class="text">{{text}}</span>
			</div>
		</div>
	</Transition>
</template>

<script>
	import {
		onMounted,
		ref
	} from 'vue'

	export default {
		name: 'HiMessage',
		props: {
			text: {
				type: String,
				default: ''
			},
			type: {
				type: String,
				// warn 警告  error 错误  success 成功
				default: 'warn'
			}
		},
		setup() {
			// 定义一个对象，包含三种情况的样式，对象key就是类型字符串
			const style = {
				warn: {
					icon: 'icon-warning',
					color: '#E6A23C',
					backgroundColor: 'rgb(253, 246, 236)',
					borderColor: 'rgb(250, 236, 216)'
				},
				error: {
					icon: 'icon-error',
					color: '#F56C6C',
					backgroundColor: 'rgb(254, 240, 240)',
					borderColor: 'rgb(253, 226, 226)'
				},
				success: {
					icon: 'icon-chenggong',
					color: '#67C23A',
					backgroundColor: 'rgb(240, 249, 235)',
					borderColor: 'rgb(225, 243, 216)'
				}
			}
			// 控制动画
			const isShow = ref(false)
			// 组件模板渲染成功后触发
			onMounted(() => {
				isShow.value = true
			})
			return {
				style,
				isShow
			}
		}
	}
</script>

<style scoped lang="scss">
	// 引入文字图标
	@import url('./icon/iconfont.css');

	.down {
		&-enter {
			&-from {
				transform: translate3d(0, -75px, 0);
				opacity: 0;
			}

			&-active {
				transition: all 0.5s;
			}

			&-to {
				transform: none;
				opacity: 1;
			}
		}
	}
	
	.my-meassage-box{
		display: flex;
		justify-content: center;
	}

	.my-message {
		position: fixed;
		z-index: 9999;
		top: 40vh;
		max-width: 80%;
		line-height: 24px;
		padding: 10px;
		border: 1px solid #e4e4e4;
		background: #f5f5f5;
		color: #999;
		border-radius: 4px;

		i {
			margin-right: 10px;
			vertical-align: middle;
		}

		.text {
			vertical-align: middle;
		}
	}
</style>