import { createApp } from 'vue'

import myLoad from '@/components/hi-loadings/HiLoading.vue'

export default {
    HiLoading: null,
    install(Vue) {
        if (this.HiLoading) {
            // 防止多次载入
            Vue.config.globalProperties.$HiLoading = this.HiLoading;
            return;
        }
        let app = createApp(myLoad);
        let _div = document.createElement("div")
        // 这里需要注意，大概率app还没有mount，导致获取不到app节点，所以想挂载到app上，需要保证app已经创建。
        document.body.appendChild(_div)
        
        this.HiLoading = app.mount(_div)

        Vue.config.globalProperties.$HiLoading = this.HiLoading;
    }
}


/**
 * 全局显示：this.$HiLoading.show('登录中...')
 * 隐藏：this.$HiLoading.hide()
 */