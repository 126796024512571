import {
	createApp
} from 'vue'
import App from './App.vue'

/* 引入 APP 公共样式 */
// import './assets/css/reset.css'
import './assets/css/normalize.css'
import './assets/css/app.scss'
// 引入文字图标样式
import './assets/icon/iconfont.css'

const app = createApp(App)

// element 组件引入
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
app.use(ElementPlus)

// 组件 提示框
import HiMessage from './components/hi-messages/HiMessage.js' // 导入提示框 组件
app.config.globalProperties.$HiMessage = HiMessage // 挂载 提示框 组件

import HiLoading from './components/hi-loadings/HiLoading.js'
app.use(HiLoading)

import hi from './common/hi.js' // 导入 自定义方法封装
app.config.globalProperties.$hi = hi // 挂载 自定义方法

import array from './common/array.js' // 导入 数组方法封装
app.config.globalProperties.$array = array // 挂载 数组方法

import api from './common/api.js' // 导入api 接口文件
app.config.globalProperties.$api = api // 挂载 api

// 路由
import router from './router'
app.use(router)

/* 
 * 用于防止多次点击保存效果，仅用于button
 * 在 button 添加  v-preventReClick 
 */
app.directive('preventReClick', (el, binding) => {
	function preventReClickFun(elValue, bindingValue) {
		if (!elValue.disabled) {
			elValue.disabled = true
			setTimeout(() => {
				elValue.disabled = false
			}, bindingValue.value || 2000)
		}
	}
	el.addEventListener('click', () => preventReClickFun(el, binding))
	binding.dir.unmounted = function() {
		el.removeEventListener('click', () => preventReClickFun(el, binding))
	}
})

app.mount('#app')