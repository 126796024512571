<template>
	<!-- 悬浮框  -->
	<div @click="open" class="hi-kefu">
		<div class="iconfont icon-kefu"></div>
		<div class="icon-text">联系客服</div>
	</div>
	
	<!-- 弹窗 -->
	<div v-show="isShow" class="hi-popup-body">
		<div class="hi-popup-box">
			<!-- <div @click="close" class="hi-popup-close">×</div> -->
			<!-- 标题 -->
			<div class="hi-popup-title">
				{{ item.title }}
			</div>
			<!-- 内容 -->
			<div class="hi-popup-form">
				<div class="hi-popup-form-li">
					确定拨打客服电话：{{ phone }}吗？
				</div>
				
			</div>

			<!-- 按钮 -->
			<div class="hi-popup-footer">
				<button @click="close" class="hi-popup-btn">取消</button>
				<button @click="tijiao" class="hi-popup-btn">确认</button>
			</div>
		</div>

	</div>
</template>

<script>
	/* 返回参数
	 * 
	 * showState：返回显示状态
	 * getData: 返回更新数据
	 */
	
	export default {
		
		name: "HiKefu",
		props: {
			// 内容数据
			item: {
				default: () => {
					return {
						title: '温馨提示',
						btnText: '立即查询',
					}
				}
			},
			phone: {
				default: ''
			}
		},
		data() {
			return {
				isShow: false,
			}
		},
		watch: {
			
		},
		created() {
			// console.log(this.newData)
		},
		methods: {
			// 关闭
			close() {
				this.isShow = false
				// this.$emit('showState', false)
			},
			// 打开
			open() {
				this.isShow = true
			},
			
			/* 
			 * 提交表单
			 */
			tijiao() {
				location.href = "tel:"+this.phone;
			}
		}

	}
</script>

<style lang="scss" scoped>
	/* 客服 s --------------------- */
	.hi-kefu{
		cursor: pointer;
		position: fixed;
		top: 50vh;
		right: 0;
		z-index: 1;
		width: 3.5rem;
		height: 3.5rem;
		border-radius: 6px;
		padding-top: 0.3rem;
		box-sizing: border-box;
		background: var(--theme-bg-color);
		display: flex;
		align-items: center;
		flex-direction: column;
		color: #fff;
	}
	.hi-kefu .iconfont{
		font-size: 1.8rem;
	}
	.hi-kefu .icon-text{
		font-size: 0.7rem;
	}
	/* 客服 e ===================== */
	
	.hi-popup-body {
		position: fixed;
		top: 0;
		left: 0;
		/* right: 0; */
		/* bottom: 0; */
		z-index: 1;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.6);
		font-size: 14px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	/*  */
	.hi-popup-box {
		width: 90vw;
		max-height: 80vh;
		background: #fff;
		overflow-y: auto;
		padding: 1em;
		box-sizing: border-box;
		border-radius: 0.5em;
		/* line-height: 2em; */
		/* font-size: 0.9em; */
		position: relative;
	}

	/* 关闭 */
	.hi-popup-close {
		font-size: 3em;
		position: absolute;
		right: 0.3em;
		top: 0;
	}

	/* 标题 */
	.hi-popup-title {
		font-size: 1.5em;
		text-align: center;
		margin-bottom: 0.8em;
	}

	/* 表单 s */
	.hi-popup-form-li {
		padding: 0.5em 0;
		/* display: flex;
		justify-content: center;
		align-items: center; */
		text-align: center;
		font-weight: 600;
		/* letter-spacing: 2px;
		/* border-bottom: 1px solid #eee; */
	}

	/* 表单 e */

	/* 按钮区 */
	.hi-popup-footer {
		margin-top: 1em;
		text-align: center;
		display: flex;
	}
	/* 按钮 */
	.hi-popup-btn {
		margin: 0 auto;
		border: none;
		width: 26.66666667vw;
		height: 8vw;
		border-radius: 1.33333333vw;
		font-size: 3.2vw;
		background-color: #1989fa;
		color: white;
		/* outline: none; */
	}
</style>